
/** PRIMARY COLOR */

$color-blue: #1787dc;
$color-yellow: #f5b923;
$color-dark-blue: #212650;

/** SECONDARY COLOR */

$color-purple: #6b60d7;
$color-coral: #ff6969;
$color-mint: #2cb5c4;

/** NEUTRAL COLOR */

$color-extra-dark: #464d56;
$color-dark: #5f6874;
$color-medium: #949eab;
$color-light: #c9d1db;
$color-extra-light: #edf0f4;