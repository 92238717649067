@charset "utf-8";

.article-solutions {
	.section-introduce {
		margin-bottom: crem(60);
	}
}

.section-solution-image {
	margin-bottom: crem(56);
}

.section-solution-content {
	text-align: center;
	margin-bottom: crem(60);

	@include breakpoint(md) {
		margin-bottom: crem(135)
	}

	&__content {
		font-size: crem(14);
		line-height: (22/14);
		color: rgba($color: #000000, $alpha: .7);
		text-align: center;
		margin-bottom: crem(30);
	}

	&__button {
		margin-bottom: crem(24);
	}

	&__hints {
		a {
			font-size: crem(14);
			font-weight: 500;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.section-solution-posts {
	margin-bottom: crem(60);

	@include breakpoint(md) {
		margin-bottom: crem(185);
	}

	.slick-dots {
		margin-top: crem(9);
	}
}

.solution-post-single {
	padding: crem(15) 0;
	height: 100%;

	&__wrap {
		box-shadow: 0 0 crem(20) rgba(0, 0, 0, 0.1);
		border: 1px solid $color-extra-light;
		border-radius: crem(8);
		padding: crem(30);
		height: 100%;
	}

	&__title {
		margin-bottom: crem(25);
	}

	&__content {
		font-size: crem(14);
		line-height: (22/14);
		margin-bottom: crem(25);
	}

	&__more {
		a {
			font-size: crem(14);
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.section-solution-voice {}

.solution-voice {
	&__wrap {
		background: $color-blue;

		@include breakpoint(md) {
			@include display-flex;
			height: crem(300);
		}
	}

	&__image {
		height: 0;
		padding-bottom: 100%;
		position: relative;
		background: #f1f1f1;

		@include breakpoint(md) {
			width: crem(300);
			height: 100%;
			padding-bottom: 0;
		}

		.image {
			height: 100%;

			@include breakpoint-down(md) {
				position: absolute;
				width: 100%;
			}

			img {
				width: 100% !important;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__content {
		@include flex(1);
		color: #ffffff;
		padding: crem(24) crem(16);
		position: relative;

		@include breakpoint(md) {
			padding: crem(40) crem(54) crem(30);
		}

		&:before {
			content: "";
			width: crem(64);
			height: crem(64);
			background: url("../img/icons/quote.svg") no-repeat;
			background-size: 100% 100%;
			position: absolute;
			top: crem(30);
			right: crem(30);
		}
	}
}

.voice {
	&__head {
		padding-bottom: crem(20);
		position: relative;
		margin-bottom: crem(30);

		&:before {
			content: "";
			width: crem(25);
			height: crem(3);
			background: currentColor;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}

	&__name {
		font-size: crem(18);
		font-weight: bold;
		line-height: normal;
		margin-bottom: crem(8);
		@extend .truncate;
	}

	&__position {
		font-size: crem(12);
		font-weight: 300;
		text-transform: uppercase;
		line-height: normal;
		@extend .truncate;
	}

	&__title {
		font-size: crem(24);
		font-weight: bold;
		line-height: (28/24);
		margin-bottom: crem(15);
		@extend .truncate;
	}

	&__content {
		@include truncate(1rem, 1.5, 4);
	}
}