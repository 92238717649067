@charset "utf-8";

/** Fonts **/

.f-one {
  font-family: "Fjalla One", sans-serif;
}

/** Links **/

a {
  color: $color-blue;
}

/** Images **/

img {
  max-width: 100%;
}

figure {
  margin: 0;
}

/** Colors **/

.text-blue {
  color: $color-blue !important;
}

.text-yellow {
  color: $color-yellow !important;
}

.text-dark-blue {
  color: $color-dark-blue !important;
}

.text-purple {
  color: $color-purple !important;
}

.text-coral {
  color: $color-coral !important;
}

.text-mint {
  color: $color-mint !important;
}

.text-extra-dark {
  color: $color-extra-dark !important;
}

.text-dark {
  color: $color-dark !important;
}

.text-medium {
  color: $color-medium !important;
}

.text-light {
  color: $color-light !important;
}

.text-extra-light {
  color: $color-extra-light !important;
}

/** Background **/

.bg-extra-light {
  background: $color-extra-light;
}

.bg-blue-20 {
  background: rgba($color: $color-blue, $alpha: .2);
}

/** Heading **/

h1,
.h1 {
  font-size: crem(48);
  line-break: (56/48);
}

h2,
.h2 {
  font-size: crem(40);
  line-height: (48/40);
}

h3,
.h3 {
  font-size: crem(36);
  line-height: (40/36);
}

h4,
.h4 {
  font-size: crem(32);
  line-height: (40/32);
}

h5,
.h5 {
  font-size: crem(24);
  line-height: (28/24);
}

h6,
.h6 {
  font-size: crem(18);
  line-height: normal;
}

.title-primary {
  line-height: normal;
  font-weight: normal;
  color: $color-light;

  strong {
    color: $color-blue;
  }
}

.title-secondary {
  font-family: "Fjalla One", sans-serif;
  color: $color-light;
  line-height: normal;
}

.text-secondary {
  font-size: crem(14);
  line-height: (22/14);
  color: $color-dark;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.article {
  padding: crem(60) 0;

  @include breakpoint(md) {
    padding: crem(155) 0 crem(200);
  }
}

.section-introduce {
  margin-bottom: crem(100);
  text-align: center;

  &__title {
    margin-bottom: crem(10);
  }

  &__desc {
    color: rgba($color: #000000, $alpha: .5);
  }
}

.list-dot {
  list-style: none;
  line-height: (28/16);
  margin: 0;
  padding-left: 1.5em;

  li::before {
    font-size: 1.5em;
    content: "\2022";
    color: #000000;
    font-weight: bold;
    display: inline-block;
    vertical-align: -0.125em;
    width: 1em;
    margin-left: -1em;
    line-height: 0;
  }
}

.list-c-check {
  font-size: crem(14);
  font-weight: 500;
  list-style: none;
  line-height: (28/14);
  margin: 0;
  padding-left: (28/14)*1em;

  li::before {
    width: (16/14)*1em;
    height: (16/14)*1em;
    display: inline-block;
    content: "";
    background: url("../img/icons/icon-c-check.svg");
    background-size: 100% 100%;
    vertical-align: -0.2em;
    margin-left: (-28/14)*1em;
    margin-right: (12/14)*1em;
    line-height: 0;
  }
}

/** Slick */

.slick-dots {
  @include display-flex;
  @include justify-content(center);
  list-style: none;
  margin: crem(24) 0 0;
  padding: 0;
  font-size: 0;

  @include breakpoint(md) {
    margin: crem(40) 0 0;
  }

  li {
    margin: 0 crem(10);

    button {
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      margin: 0;
      width: crem(12);
      height: crem(12);
      border: none;
      outline: none;
      background: $color-light;
      border-radius: 50%;
      font-size: 0;
    }
  }

  .slick-active {
    button {
      background: $color-blue;
    }
  }
}

/** Tabs */

.tab-content {

  position: absolute;
  opacity: 0;

  &.current {
    position: relative;
    opacity: 1;
  }
}

/** Wordpress */

/* page */

.page {
  .entry-title {
    font-size: crem(30);
    margin: crem(20) 0;
  }

  .entry-content {
    margin: crem(20) 0;
  }

  h2 {
    font-size: crem(28);
    margin: crem(18) 0;
  }

  h3 {
    font-size: crem(26);
    margin: crem(16) 0;
  }
}