@charset "utf-8";

// VARIABLES

@import "parts/variables";

// MIXINS
@import "parts/css3-mixins";
@import "parts/mixins";

/*---------------------------------
* GLOBAL
-----------------------------------*/

@import "parts/global";
@import "parts/icons";
@import "parts/buttons";

/*---------------------------------
* LAYOUT
-----------------------------------*/

@import "parts/layout";

/*---------------------------------
* PAGES
-----------------------------------*/

@import "pages/home";
@import "pages/pricing";
@import "pages/how-it-works";
@import "pages/why-us";
@import "pages/our-difference";
@import "pages/competitors";
@import "pages/solutions";
@import "pages/get-started";
@import "pages/about";

