.started-tabs {
	list-style: none;
	padding: 0;
	margin: 0;

	@include breakpoint-down(lg) {
		@include display-flex;
		margin: 0 0 crem(24);
	}

	&__item {
		padding: crem(16) 0;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 1rem;
		}

		&.current {
			@include box-shadow(0, crem(-3), 0, $color-blue, inset);
		}

		@include breakpoint(lg) {
			margin: 0 0 crem(10) !important;
			padding: crem(16) crem(16) crem(16) crem(22);
			border-color: transparent;
			border-style: solid;
			border-width: 1px 0;

			&.current {
				background: rgba(237, 240, 244, 0.2);
				border-color: #edf0f4;
				@include box-shadow(crem(3), 0, 0, $color-blue);
			}
		}
	}

	&__title {
		font-weight: bold;

		.started-tabs__item.current & {
			color: $color-blue;
		}
	}

	&__content {
		font-size: crem(14);
		color: $color-medium;
		margin-top: crem(8);
	}
}

.started-content {
	&:before {
		content: "";
		width: 1px;
		height: 100%;
		background-image: linear-gradient($color-medium 33%, rgba(255, 255, 255, 0) 0%);
		background-position: right;
		background-size: 1px crem(8);
		background-repeat: repeat-y;
		position: absolute;
		left: crem(23);
		top: 0;
		z-index: -1;
	}

	&__item {
		margin-bottom: crem(32);
	}

	&__head {
		@include display-flex;
		@include align-items(center);
	}

	&__icon {
		margin-right: crem(30);
		background: #ffffff;
	}

	&__title {
		font-size: crem(14);
		font-weight: bold;
		@include flex(1);
	}

	&__body {
		padding-left: crem(78);

		img {
			margin-left: crem(-20);
		}
	}

	&__desc {
		font-size: crem(14);
		color: $color-medium;
		margin-bottom: crem(44);
	}
}