@charset "utf-8";

svg[role="img"] {
	background-color: transparent;
	fill: currentColor;
	display: inline-block;
	vertical-align: middle;
	width: crem(24);
	height: crem(24);
}

.icon {
	display: inline-block;
	vertical-align: middle;
	width: crem(16);
	height: crem(16);
	background-repeat: no-repeat;
	background-size: 100% 100%;


	&--18 {
		width: crem(18);
		height: crem(18);
	}

	&--24 {
		width: crem(24);
		height: crem(24);
	}

	&--32 {
		width: crem(32);
		height: crem(32);
	}

	&--40 {
		width: crem(40);
		height: crem(40);
	}

	&--48 {
		width: crem(48);
		height: crem(48);
	}

	&-message {
		background-image: url("../img/icons/icon-message.svg");
	}

	&-thumbsup {
		background-image: url("../img/icons/icon-thumbsup.svg");
	}

	&-email {
		background-image: url("../img/icons/icon-email.svg");
	}

	&-group {
		background-image: url("../img/icons/icon-group.svg");
	}

	&-talk {
		background-image: url("../img/icons/icon-talk.svg");
	}

	&-check {
		background-image: url("../img/icons/icon-check.svg");
	}

	&-remove {
		background-image: url("../img/icons/icon-remove.svg");
	}

	&-person {
		background-image: url("../img/icons/icon-person.svg");
	}

	&-magic  {
		background-image: url("../img/icons/icon-magic.svg");
	}

	&-edit {
		background-image: url("../img/icons/icon-edit.svg");
	}

	&-add {
		background-image: url("../img/icons/icon-add.svg");
	}

	&-new-notification {
		background-image: url("../img/icons/icon-new-notification.svg");
	}

	&-palette {
		background-image: url("../img/icons/icon-palette.svg");
	}

	&-lock {
		background-image: url("../img/icons/icon-lock.svg");
	}

	&-plug {
		background-image: url("../img/icons/icon-plug.svg");
	}

	&-people {
		background-image: url("../img/icons/icon-people.svg");
	}

	&-medal {
		background-image: url("../img/icons/icon-medal.svg");
	}

	&-world {
		background-image: url("../img/icons/icon-world.svg");
	}
}