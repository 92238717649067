.btn {
	@include inline-flex;
	@include align-items(center);
	@include justify-content(center);
	height: crem(48);
	padding: 0 crem(28);
	font-size: crem(16);

	&:focus,
	.focus {
		box-shadow: none;
	}
}

.btn-radius {
	@include border-radius(crem(60));
	transition: all 300ms ease;

	&:hover {
		@include box-shadow(0, crem(5), crem(20), rgba(0, 0, 0, 0.2));
	}
}

.btn-grad-blue {
	background: linear-gradient(15deg, #13baf3 -13%, #1f49c0 114.18%);
	color: #ffffff;
	border: none;

	&:hover {
		color: #ffffff;
	}
}

.btn-grad-yellow {
	background: linear-gradient(69.89deg, #f5b923 2.88%, #f59300 95.16%);
	color: #ffffff;
	border: none;

	&:hover {
		color: #ffffff;
	}
}

.btn-blue {
	color: #ffffff;
	background-color: $color-blue;
	border-color: $color-blue;

	&:hover {
		color: #ffffff;
	}
}

.btn-outline-blue {
	color: $color-blue;
	background-color: transparent;
	background-image: none;
	border: 1px solid $color-blue;
	border-color: $color-blue;

	&:hover {
		color: #ffffff;
		background-color: $color-blue;
	}
}

.btn-yellow {
	color: #ffffff;
	background-color: $color-yellow;
	border-color: $color-yellow;

	&:hover {
		color: #ffffff;
	}
}