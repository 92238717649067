@charset "utf-8";

.section-difference-introduce {
	margin-bottom: crem(6);

	&__title {
		line-height: (36/24);
		margin-bottom: crem(8);
	}

	&__content {
		color: #333333;
		font-weight: 300;
		margin-bottom: crem(15);
	}

	&__button {
		.btn {
			min-width: crem(166);
			margin: crem(15);
		}

		margin-bottom: crem(18);
	}
}

.section-difference-list {
	&__item {
		margin-bottom: crem(60);
	}

	&__head {
		@include display-flex;
		@include align-items(center);
		margin-bottom: crem(8);
	}

	&__serial {
		font-size: crem(48);
		font-weight: bold;
		color: $color-blue;
		margin-right: crem(5);
		line-height: normal;
	}

	&__title {
		font-size: crem(18);
		font-weight: bold;
		color: #333333;
		line-height: 1.2;
	}

	&__body {
		font-size: crem(14);
		line-height: (22/14);
		color: $color-medium;
	}
}