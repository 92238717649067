@charset "utf-8";

.article-how-it-works {
	background: url("../img/how-it-works-bg.svg") no-repeat 100% 100%;
}

.section-blog {
	margin-bottom: crem(100);

	&:nth-child(even) {
		> .row {
			@include flex-direction(row-reverse);
		}
	}

	&__image {
		text-align: center;

		@include breakpoint-down(md) {
			margin-bottom: crem(24);
		}
	}

	&__content {
		color: $color-dark;
	}

	&__title {
		font-weight: bold;
		margin-bottom: crem(16);
		letter-spacing: 0.05em;
	}

	&__desc {
		margin-bottom: crem(20);
	}

	&__detail {
		ul {
			@extend .list-dot;
		}
	}
}

.section-get-started {
	text-align: center;

	&__title {
		font-size: crem(40);
		font-weight: bold;
		line-height: (48/40);
		margin-bottom: crem(34);
	}
}