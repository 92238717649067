@charset "utf-8";

.section-mission {
	padding: crem(32) 0;
	position: relative;

	@include breakpoint-down(md) {
		min-height: crem(430);
		margin-bottom: crem(100);
	}

	&__desc {
		font-size: crem(18);
		font-weight: bold;
		color: $color-medium;
		margin-bottom: crem(20);
	}

	&__text {
		line-height: (28/16);
		margin-bottom: crem(36);
	}

	&__image {
		img {
			max-width: none;
		}

		@include breakpoint-down(md) {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			z-index: -1;
			opacity: .2;
			text-align: center;

			img {
				width: auto !important;
				height: 100%;
			}
		}
	}

	&__button {
		@include display-flex;
		@include flex-wrap(wrap);
		margin-bottom: crem(15);

		@include breakpoint-down(md) {
			@include justify-content(center);
		}

		.btn {
			margin-top: crem(10);
			margin-bottom: crem(10);
			min-width: crem(166);

			&:not(:last-child) {
				margin-right: crem(10);

				@include breakpoint(md) {
					margin-right: crem(30);
				}
			}
		}
	}

	&__download {
		@include display-flex;
		@include flex-wrap(wrap);

		@include breakpoint-down(md) {
			@include justify-content(center);
		}

		.btn-icon {
			display: inline-block;
			vertical-align: middle;
			margin-top: crem(10);
			margin-bottom: crem(10);

			&:not(:last-child) {
				margin-right: crem(10);

				@include breakpoint(md) {
					margin-right: crem(30);
				}
			}
		}
	}
}

.section-features {
	margin-bottom: crem(64);

	@include breakpoint(md) {
		margin-bottom: crem(120);
	}

	&__title {
		margin-bottom: crem(28);

		@include breakpoint(md) {
			text-align: center;
			margin-bottom: crem(48);
		}
	}

	&__image {
		@include display-flex;
		@include flex-direction(column);
		@include align-items(center);
		position: relative;
		z-index: -1;

		@include breakpoint(md) {
			@include align-items(flex-end);
			z-index: 1;
		}

		img {
			max-width: 200%;

			@include breakpoint(md) {
				max-width: none;
			}
		}
	}

	&__side {
		@include breakpoint-down(md) {
			margin-top: crem(-20);
		}
	}

	&__form {
		margin-top: crem(-60);
		position: relative;
		z-index: 10;

		@include breakpoint(md) {
			margin-top: crem(-70);
		}
	}
}

.form-try {
	text-align: center;

	@include breakpoint(md) {
		width: 80%;
		margin: 0 auto;
		text-align: left;
	}

	&__title {
		font-size: crem(24);
		margin-bottom: crem(10);
	}

	&__content {
		@include breakpoint(md) {
			@include display-flex;
		}
	}

	&__input {
		@include flex(1);

		@include breakpoint-down(md) {
			margin-bottom: crem(16);
		}

		input[type="text"] {
			display: block;
			width: 100%;
			height: crem(48);
			background: transparent;
			border: 1px solid rgba(0, 0, 0, .3);
			outline: none;
			padding: 0 crem(18);
			@include border-radius(crem(60));
			color: rgba(0, 0, 0, .4);
			font-size: crem(16);

			&::placeholder {
				color: rgba(0, 0, 0, .4);
			}
		}
	}

	&__button {
		.btn {
			width: 100%;
		}

		@include breakpoint(md) {
			margin-left: crem(10);
		}
	}
}

.ls-feature {
	&__item {
		&:not(:last-child) {
			margin-bottom: crem(32);

			@include breakpoint(md) {
				margin-bottom: crem(60);
			}
		}
	}

	&__wrap {
		@include display-flex;
	}

	&__content {
		@include flex(1);
	}

	&__icon {
		margin-right: crem(24);
		padding-top: crem(5);
	}

	&__desc {
		font-size: crem(14);
		color: $color-dark;
	}
}

.section-solutions {
	margin-bottom: crem(100);

	&__head {
		text-align: center;
		margin-bottom: crem(48);
	}

	&__desc {
		font-size: crem(14);
		color: rgba(0, 0, 0, .7);
	}
}

.solution-single {
	text-align: center;

	@include breakpoint(md) {
		margin-bottom: crem(30);
	}

	&__title {
		font-weight: 500;
		margin-bottom: crem(16);
		@extend .truncate;
	}

	&__image {
		height: 0;
		padding-bottom: (164/263)*100%;
		position: relative;
		margin-bottom: crem(24);

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100%;
			object-fit: cover;
		}
	}
}

.section-services {
	background: #f7f7f7;
	padding: crem(70) 0;
	text-align: center;

	&__title {
		font-size: crem(18);
		font-weight: 500;
		line-height: (25/18);
		color: rgba($color: #000000, $alpha: .8);
		margin-bottom: crem(24);
	}
}

.logo-single {
	margin: crem(15) 0;
}