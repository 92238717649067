@charset "utf-8";

html {
	font-size: 16px;
}

@include breakpoint(xxl) {
	html {
		font-size: 0.8333333333333333vw;
	}

	.container {
		max-width: 71.25rem;
	}
}

body {
	font-family: "Roboto", sans-serif;
	font-size: 1rem;
	color: #333333;

	&.lock {
		@include breakpoint-down(md) {
			overflow: hidden;
		}
	}
}

.app-container {
	overflow: hidden;
}

/** Header **/

.app-header {
	.navbar-toggler {
		padding: 0;
		border: none;
		color: $color-blue;
		outline: none;
	}

	.navbar {
		padding: crem(32) crem(16) crem(16);
		background: rgba($color: #ffffff, $alpha: .9);
		transition: padding 300ms ease;

		&.fixed-top {
			padding-top: crem(16);

			.navbar-btn {
				.nav-link {
					height: crem(32);
				}
			}
		}
	}

	.navbar-brand {
		padding: 0;
		margin-right: crem(20);
	}

	.navbar-nav {
		@include breakpoint-down(lg) {
			padding: 0 crem(30);
			overflow-y: auto;
			max-height: calc(100vh - 4rem);
		}

		.nav-item {
			position: relative;

			@include breakpoint(lg) {
				margin: 0 crem(20);
			}

			&:hover {
				.nav-link {
					color: #ffffff;

					@include breakpoint(lg) {
						color: $color-blue;

						&::before {
							transform: scaleX(1);
						}
					}
				}

				.subnav {
					visibility: visible;
					opacity: 1;
					transform: translateY(0%);
					transition-delay: 0s, 0s;
				}
			}

			&.active,
			&.current-menu-parent,
			&.current_page_parent {
				.nav-link {
					color: #ffffff;

					@include breakpoint(lg) {
						color: $color-blue;

						&::before {
							transform: scaleX(1);
						}
					}
				}
			}

			.nav-expand {
				position: absolute;
				right: 0;
				top: crem(10);

				&.show {
					transform: rotate(90deg);
				}
			}
		}

		.nav-link {
			color: #ffffff;
			padding: crem(10) crem(15) crem(10) crem(30);
			position: relative;
			font-size: crem(18);

			@include breakpoint(lg) {
				font-size: crem(16);
				color: #333333;
				padding: crem(3) 0;
				text-align: center;

				&::before {
					content: "";
					display: block;
					width: 100%;
					height: crem(3);
					background: currentColor;
					position: absolute;
					left: 0;
					bottom: 0;
					transform: scaleX(0);
					transition: transform 150ms ease-in-out;
				}
			}

			@include breakpoint-down(lg) {
				&.data-acc {
					@include display-flex;
					@include align-items(center);

					&:after {
						display: inline-block;
						content: "";
						width: crem(24);
						height: crem(24);
						background: url("../img/icons/icon-right.svg") no-repeat;
						background-size: 100% 100%;
						margin-left: auto;
					}

					&.open {
						&:after {
							transform: rotate(90deg);
						}
					}
				}
			}
		}
	}

	.navbar-mobile {
		@include breakpoint-down(lg) {
			opacity: 0;
			position: fixed;
			top: 0;
			right: -100%;
			width: 100%;
			height: 100%;
			background: $color-dark-blue;
			color: #ffffff;
			z-index: 100;
			padding: crem(30) 0;
			transition: all 300ms ease-in-out;
			z-index: 1040;

			&.active {
				opacity: 1;
				right: 0;
			}
		}
	}

	.navbar-mobile-close {
		margin-bottom: crem(24);
		padding: 0 crem(30);

		@include breakpoint(lg) {
			display: none;
		}
	}

	.subnav {
		display: none;

		@include breakpoint(lg) {
			display: block !important;
			position: absolute;
			top: 100%;
			left: crem(-20);
			padding-top: crem(18);
			width: crem(224);
			z-index: 10;
			visibility: hidden;
			opacity: 0;
			transform: translateY(-0.5em);
			transition: all 0.25s ease-in-out 0s, visibility 0s linear 0.25s;
		}
	}

	.subnav-list {
		padding: 0;
		margin-bottom: crem(20);

		@include breakpoint(lg) {
			background: #ffffff;
			padding: crem(8);
			margin-bottom: 0;
			@include box-shadow(0, 0, crem(20), rgba(0, 0, 0, 0.1));
			@include border-radius(crem(8));
		}
	}

	.subnav-item {
		list-style: none;

		@include breakpoint(lg) {
			&:not(:last-child) {
				margin-bottom: crem(8);
			}

			&:hover {
				.subnav-link {
					background: $color-blue;
					color: #ffffff;
				}
			}
		}
	}

	.subnav-link {
		display: block;
		font-size: crem(16);
		color: #ffffff;
		padding: crem(5) crem(60);
		text-decoration: none;

		@include breakpoint(lg) {
			padding: crem(8) crem(16);
			@include border-radius(crem(60));
			font-size: crem(14);
			color: #333333;
			text-decoration: none;
			@include transition(all 100ms ease);
		}
	}

	.navbar-btn {
		.nav-item {
			margin: 0;

			&:first-child {
				.nav-link {
					background: linear-gradient(15deg, #13baf3 -13%, #1f49c0 114.18%);
					color: #ffffff;
					border: none;
					white-space: nowrap;

					&:hover {
						@include box-shadow(0, crem(5), crem(20), rgba(0, 0, 0, 0.2));
					}
				}
			}
		}

		.nav-link {
			@include inline-flex;
			@include align-items(center);
			@include justify-content(center);
			height: 3rem;
			padding: 0 1.75rem;
			font-size: 1rem;
			color: $color-blue;
			border-radius: crem(60);
			transition: all 300ms ease;

			&:before {
				display: none;
			}
		}
	}
}

/** Footer **/

.app-footer {
	background: $color-dark-blue;
	color: #ffffff;
	padding: crem(50) 0;

	a {
		color: inherit;
		text-decoration: none;
	}

	>.container {
		@include breakpoint-down(md) {
			padding: 0 crem(30);
		}
	}
}

.footer-sitemap {
	list-style: none;
	padding: 0;

	.menu-item {
		line-height: normal;
		margin-bottom: crem(16);
	}
}

.footer-social {
	@include display-flex;
	@include flex-wrap(wrap);
	margin-bottom: crem(24);

	&__item {
		&:not(:last-child) {
			margin-right: crem(20);
		}
	}

	svg[role="img"] {
		width: crem(20);
		height: crem(20);
	}
}

.footer-subscribe {
	&__title {
		font-size: crem(14);
		line-height: (20/14);
		margin-bottom: crem(16);
	}
}

.form-subscribe {
	@include display-flex;

	&__input {
		@include flex(1);

		.form-control {
			display: block;
			width: 100%;
			height: crem(32);
			font-size: crem(14);
			background: transparent;
			border: 1px solid rgba(255, 255, 255, .4);
			outline: none;
			padding: 0 crem(18);
			@include border-radius(crem(60));
			color: rgba(255, 255, 255, .4);

			&::placeholder {
				color: rgba(255, 255, 255, .4);
			}
		}
	}

	&__button {
		margin-left: crem(8);

		.btn {
			height: crem(32);
			font-size: crem(14);
		}
	}
}