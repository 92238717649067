@charset "utf-8";

.article-pricing {
	min-height: crem(854);
	position: relative;
	background: url("../img/pricing-bg.svg") no-repeat 50% 100%;
	background-size: crem(1189) auto;

	.section-introduce {
		margin-bottom: crem(52);
	}
}

.section-pricing {
	&__wrap {
		position: relative;
		z-index: 1;
	}

	.slick-dots {
		margin-top: 0;
	}
}

.pricing-list {
	@include display-flex;
	@include flex-wrap(wrap);
	@include justify-content(center);

	&__item {
		padding: crem(30);

		@include breakpoint(md) {
			width: crem(300);
		}
	}

	.slick-track {
		@include breakpoint-down(md) {
			height: 100%;
		}
	}
}

.pricing-single {
	background: #ffffff;
	@include box-shadow(0, crem(4), crem(20), rgba(0, 0, 0, .2));
	@include border-radius(crem(8));
	padding: crem(32) crem(24) crem(24);
	text-align: center;
	@include display-flex;
	@include flex-direction(column);
	height: 100%;

	&__title {
		font-size: crem(28);
		font-weight: 500;
		line-height: (43/28);
		margin-bottom: crem(18);
		@extend .truncate;
	}

	&__price {
		font-size: crem(24);
		font-weight: bold;
		line-height: normal;
		color: $color-blue;
		margin-bottom: crem(16);
		@extend .truncate;

		small {
			font-size: (14/24)*1em;
			font-weight: normal;
			color: rgba($color: #000000, $alpha: .5)
		}
	}

	&__content {
		font-size: crem(14);
		color: rgba($color: #000000, $alpha: .7);
		line-height: (28/14);
		margin-bottom: crem(16);
		@include flex-grow(1);

		p {
			margin-bottom: 0;
		}
	}

	&__button {
		.btn {
			min-width: crem(155);
		}
	}
}