@mixin breakpoint($point) {
	@if $point == xxl {
		@media (min-width: 2560px) { @content ; }
	}
	@if $point == xl {
		@media (min-width: 1200px) { @content ; }
	}
	@else if $point == lg {
		@media (min-width: 992px) { @content ; }
	}
	@else if $point == md {
		@media (min-width: 768px) { @content ; }
	}
	@else if $point == sm {
		@media (min-width: 576px)  { @content ; }
	}
}

@mixin breakpoint-down($point) {
	@if $point == xxl {
		@media (min-width: 2559px) { @content ; }
	}
	@if $point == xl {
		@media (max-width: 1199px) { @content ; }
	}
	@else if $point == lg {
		@media (max-width: 991px) { @content ; }
	}
	@else if $point == md {
		@media (max-width: 767px) { @content ; }
	}
	@else if $point == sm {
		@media (max-width: 577px)  { @content ; }
	}
}

@function crem($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

@mixin truncate($font-size, $line-height, $lines-to-show) {
	display: -webkit-box;
  max-height: $font-size*$line-height*$lines-to-show;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}