@charset "utf-8";

.section-competitors {
	margin-bottom: crem(120);
}

.tb-competitors {
	width: 100%;
	font-size: crem(14);
	font-weight: normal;
	line-height: (22/14);

	p {
		margin-bottom: 0;
	}

	caption {
		padding: crem(24) crem(16);
		color: $color-dark-blue;
	}

	th,
	td {
		border: 1px solid $color-light;
		padding: crem(14);
		vertical-align: middle;
	}

	thead {
		th {
			text-transform: uppercase;
			color: $color-medium;
			text-align: center;
		}
	}

	tbody {
		td {
			text-align: center;
		}
	}
}

.blog-competitors {
	&__label {
		font-size: crem(14);
		font-weight: bold;
		text-transform: uppercase;
		color: $color-light;
		margin-bottom: crem(10);
	}

	&__title {
		margin-bottom: crem(16);
	}

	&__desc {
		font-size: crem(14);
		line-height: (24/14);
		color: $color-extra-dark;
		margin-bottom: crem(22);
	}

	&__cont {
		margin-bottom: crem(16);

		ul {
			@extend .list-c-check;
		}
	}
}