@charset "utf-8";

.section-about-introduce {
	padding: crem(60) 0;

	@include breakpoint(md) {
		padding: crem(200) 0 crem(100);
	}

	&__title {
		font-weight: 300;
		margin-bottom: crem(16);

		strong {
			font-weight: bold;
		}
	}
}

.section-about-blog {
	margin-bottom: crem(15);

	@include breakpoint(md) {
		margin-bottom: crem(55);
	}
}

.section-our-values {
	padding: crem(40) 0;
	background: rgba(246, 247, 250, 0.5);

	@include breakpoint(md) {
		padding: crem(80) 0;
	}

	&__head {
		margin-bottom: crem(50);
		text-align: center;
	}
}

.our-value-single {
	text-align: center;
	margin: crem(25) 0;

	&__icon {
		margin-bottom: crem(16);
	}

	&__title {
		font-size: crem(18);
		font-weight: 500;
		line-height: normal;
		margin-bottom: crem(10);
	}
}

.section-our-teams {
	padding: crem(40) 0;

	@include breakpoint(md) {
		padding: crem(135) 0;
	}

	&__title {
		margin-bottom: crem(40);

		@include breakpoint(md) {
			margin-bottom: crem(70);
		}
	}
}

.teams {
	&__image {
		height: 100%;
		@include display-flex;
		@include justify-content(center);
		@include align-items(flex-end);
	}

	&__content {
		height: 100%;
		@include display-flex;
		@include flex-direction(column);
		@include justify-content(center);
		padding: crem(24) 0;

		@include breakpoint(lg) {
			padding: crem(24);
		}

		.teams--master & {
			@include breakpoint(xl) {
				padding: crem(24) crem(80);
			}
		}
	}

	&__name {
		font-size: crem(18);
		font-weight: bold;
		line-height: (22/18);
		letter-spacing: (2/18)*1em;
		text-transform: uppercase;
		margin-bottom: crem(8);
	}

	&__position {
		font-size: crem(14);
		line-height: normal;
		color: $color-medium;
		margin-bottom: crem(8);
	}
}

.about-blog-single {
	margin: crem(30) 0;

	@include breakpoint-down(md) {
		text-align: center;
	}
}