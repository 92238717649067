@charset "utf-8";

.section-try-it {
	background: rgba($color: $color-extra-light, $alpha: .5);
	padding: crem(30) 0;

	@include breakpoint(md) {
		padding: crem(75) 0;
	}

	&__wrap {
		@include breakpoint(lg) {
			@include display-flex;
			@include align-items(flex-end);
		}
	}

	&__content {
		@include flex(1);
		font-size: crem(18);
		color: $color-blue;

		p {
			margin-bottom: 0;
		}
	}

	&__button {
		.btn {
			min-width: crem(154);
			font-size: crem(16);
			margin: crem(10);
		}
	}
}

.section-why-us-introduce {
	text-align: center;
	margin-bottom: crem(60);

	@include breakpoint(md) {
		margin-bottom: crem(106);
	}

	&__title {
		font-weight: 500;
		color: $color-medium;
		line-height: (19/16);
		margin-bottom: crem(25);
		letter-spacing: (1/16)*1em;
	}

	&__content {
		font-size: crem(24);
		font-weight: 500;
		line-height: (32/24);
		margin-bottom: crem(28);
	}
}

.section-why-us-image {
	margin-bottom: crem(60);
	text-align: center;

	@include breakpoint(md) {
		margin-bottom: crem(128);
	}
}

.section-why-us-blogs {
	&__item {
		&:not(:last-child) {
			margin-bottom: crem(40);

			@include breakpoint(md) {
				margin-bottom: crem(100);
			}
		}

		@include display-flex;
	}

	&__icon {
		margin-right: crem(32);
	}

	&__content {
		@include flex(1);
	}

	&__title {
		margin-bottom: crem(10);
	}

	&__detail {
		font-size: crem(14);
		color: $color-dark;
		line-height: (22/14);
	}
}